import classNames from "classnames";
import { HeadingLevel } from "types/HeadingLevel";

export function Heading(props) {
    const content = props.text ?? props.children;
    let Tag = props.level ?? HeadingLevel.H1;

    return (
        <Tag className={classNames("page-heading", props.className)} style={props.style}>
            {content}
        </Tag>
    );
}
