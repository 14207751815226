import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { getFormData } from "components/utils";
import { ApplicationStepper } from "./ApplicationStepper";
import { ApplicationControls } from "./ApplicationControls";
import { ApplicationForm } from "./ApplicationForm";
import { AppContext } from "components/App/AppContext";
import { getFormNameFromConfig, submitByRefPromise } from "components/JsonForm/utils";
import { ErrorSummary } from "components/ErrorSummary";
import { ApplicationPageContainer } from "./ApplicationPageContainer";
import { isEmpty, isEqual, isNil } from "lodash";
import { ApplicationPageProcessing } from "./ApplicationPageProcessing";
import {
    createApplication,
    getApplicationFormData,
    getStepperColors,
    submitApplicationWizard,
    validateContactsWidget,
    validateContactsWidgetDataFormat,
    waitForEncryptedFields,
} from "./utils";
import { PageLink } from "components/App/utils";
import { ErrorSummaryInterface } from "types/ErrorSummary";
import { PageNotFoundPage } from "../PageNotFoundPage";
import { isInIframe } from "components/utils/dom";
import { PortalBuilderPreviewApplicationPage } from "./PortalBuilderPreviewApplicationPage";
import { Spinner } from "react-bootstrap";
import { scrollToFirstError } from "components/JsonForm/validation";
import { AnalyticsEventType, sendAnalyticsEvent } from "components/utils/analytics";
import { ApplicationNotAllowedPage } from "./ApplicationNotAllowedPage";

export function ApplicationPage() {
    const location = useLocation();
    const history = useHistory();
    const portalConfig = useContext(AppContext);
    const {
        programNumber,
        requirements: { allowPortalApplication, portalWizardStepList, prescreenRequiredFields },
    } = portalConfig;
    const [applicationForm, setApplicationForm] = useState<{
        configuration?: { schema: any; uiSchema: any };
        data: any;
        previousPageNumber?: string;
        nextPageNumber?: string;
    }>({
        configuration: undefined,
        data: undefined,
        previousPageNumber: undefined,
        nextPageNumber: undefined,
    });

    const [isLoading, setIsLoading] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isFormLoaded, setIsFormLoaded] = useState(false);
    const [errorSummary, setErrorSummary] = useState<ErrorSummaryInterface>();

    const applicationFormRef = useRef();
    const contactsWidgetRef = useRef();
    const additionalContactsWidgetRef = useRef();
    const workflowWidgetRef = useRef();
    const encryptState = useRef(new Map()); // Keys of encrypted fields with editing in progress

    const applicationNumber = new URLSearchParams(location.search).get("applicationNumber");
    const pageNumber = new URLSearchParams(location.search).get("pageNumber");
    const { previousPageNumber, nextPageNumber } = applicationForm;

    const isNewApplication = isEmpty(applicationNumber);
    const isFirstPage = isEmpty(previousPageNumber);
    const isLastPage = isEmpty(nextPageNumber) && !isFirstPage;
    const redirectToPrescreenPage = isNewApplication && (prescreenRequiredFields || []).length > 0;

    const applicationDataLoaded = !isNil(applicationNumber) && !isNil(programNumber) && !isNil(applicationForm.configuration);

    const formName = getFormNameFromConfig(applicationForm.configuration);

    const isDisqualificationPage = applicationForm.configuration?.uiSchema["af:disqualificationPage"] === "Y";

    // Create application
    useEffect(() => {
        async function createApp() {
            if (isNewApplication && programNumber && pageNumber && allowPortalApplication) {
                try {
                    setErrorSummary(undefined);
                    const response = await createApplication(programNumber, pageNumber);

                    sendAnalyticsEvent(AnalyticsEventType.APPLICATION_CREATED, { applicationNumber: response.number });

                    history.replace(`${PageLink.Application}?applicationNumber=${response.number}&pageNumber=${pageNumber}`);
                } catch (error: any) {
                    setIsLoading(false);
                    setErrorSummary(error);
                }
            }
        }

        createApp();
    }, [history, isNewApplication, pageNumber, programNumber, allowPortalApplication]);

    // Load application form data
    useEffect(() => {
        async function loadForm() {
            if (applicationNumber && pageNumber && allowPortalApplication) {
                try {
                    setErrorSummary(undefined);
                    // Get application form
                    const response = await getFormData(applicationNumber, pageNumber);
                    setApplicationForm({
                        configuration: response.formConfiguration,
                        data: response.formDetails.fields,
                        previousPageNumber: response.pageNavigation.previousPage,
                        nextPageNumber: response.pageNavigation.nextPage,
                    });
                    setIsFormLoaded(false);
                } catch (error: any) {
                    setErrorSummary(error);
                } finally {
                    setIsLoading(false);
                    setIsProcessing(false);
                    // Scroll to the top of the page when navigating through forms
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }
            }
        }

        loadForm();
    }, [applicationNumber, pageNumber, allowPortalApplication]);

    const abandonmentTracking = useAbandonmentTracking(applicationNumber, formName, pageNumber, isNewApplication);

    const onContinue = useCallback(async () => {
        if (applicationNumber && pageNumber) {
            try {
                // Wait for all encrypted fields to finish editing.
                await waitForEncryptedFields(encryptState.current);

                // validate contacts widget
                await validateContactsWidget(contactsWidgetRef, formName);

                // validate additional contacts widget
                await validateContactsWidget(additionalContactsWidgetRef, formName);

                // Validate application form
                await submitByRefPromise(applicationFormRef);

                const configuration = {
                    schema: applicationForm.configuration?.schema,
                    uiSchema: applicationForm.configuration?.uiSchema,
                };

                const { applicationElements, applicationItems, applicationContacts } = getApplicationFormData(
                    applicationFormRef,
                    contactsWidgetRef,
                    additionalContactsWidgetRef,
                    configuration,
                    applicationForm.data
                );

                setIsProcessing(true);
                setErrorSummary(undefined);

                const response = await submitApplicationWizard(applicationNumber, pageNumber, false, {
                    applicationElements,
                    applicationItems,
                    applicationContacts,
                });

                abandonmentTracking.pageNavigated = pageNumber;

                // Show thank you page if the app is completed or there is no next page available
                if (
                    isEmpty(response.nextPageNumber) ||
                    isEqual(response.nextPageNumber, pageNumber) ||
                    response.nextPathLocation === "appcompleted"
                ) {
                    sendAnalyticsEvent(AnalyticsEventType.APPLICATION_SUBMITTED, { applicationNumber });

                    history.push(
                        `${PageLink.ApplicationSubmitted}?projectNumber=${response.projectNumber}&applicationNumber=${applicationNumber}`
                    );
                    return;
                }

                // Clear the current form data to not rerender the form while loading the next one.
                setApplicationForm({
                    configuration: undefined,
                    data: undefined,
                    previousPageNumber: undefined,
                    nextPageNumber: undefined,
                });

                // Navigate to next page.
                history.push(`${PageLink.Application}?applicationNumber=${applicationNumber}&pageNumber=${response.nextPageNumber}`);
            } catch (error: any) {
                setIsProcessing(false);
                setErrorSummary(error);

                // Scroll to first error
                setTimeout(scrollToFirstError);
            }
        }
    }, [
        applicationNumber,
        pageNumber,
        formName,
        applicationForm.configuration?.schema,
        applicationForm.configuration?.uiSchema,
        applicationForm.data,
        abandonmentTracking,
        history,
    ]);

    const onBack = useCallback(async () => {
        try {
            // Show processing message instead of blank page.
            setIsProcessing(true);
            setErrorSummary(undefined);

            abandonmentTracking.pageNavigated = pageNumber;

            // Clear the current form data to not rerender the form while loading the next one.
            setApplicationForm({
                configuration: undefined,
                data: undefined,
                previousPageNumber: undefined,
                nextPageNumber: undefined,
            });

            history.push(`${PageLink.Application}?applicationNumber=${applicationNumber}&pageNumber=${previousPageNumber}`);
        } catch (error: any) {
            setIsProcessing(false);
            setErrorSummary(error);
        }
    }, [abandonmentTracking, pageNumber, history, applicationNumber, previousPageNumber]);

    const onSaveDraft = useCallback(async () => {
        if (applicationNumber && pageNumber) {
            try {
                // Wait for all encrypted fields to finish editing.
                await waitForEncryptedFields(encryptState.current);

                // validate contacts widget
                await validateContactsWidgetDataFormat(contactsWidgetRef, formName);

                // validate additional contacts widget
                await validateContactsWidgetDataFormat(additionalContactsWidgetRef, formName);

                const configuration = {
                    schema: applicationForm.configuration?.schema,
                    uiSchema: applicationForm.configuration?.uiSchema,
                };

                const { applicationElements, applicationItems, applicationContacts } = getApplicationFormData(
                    applicationFormRef,
                    contactsWidgetRef,
                    additionalContactsWidgetRef,
                    configuration,
                    applicationForm.data
                );

                setIsProcessing(true);
                setErrorSummary(undefined);

                await submitApplicationWizard(applicationNumber, pageNumber, true, {
                    applicationElements,
                    applicationItems,
                    applicationContacts,
                });

                abandonmentTracking.pageNavigated = pageNumber;

                sendAnalyticsEvent(AnalyticsEventType.APPLICATION_SAVED, { applicationNumber, formName });

                history.push(PageLink.CustomerHome);
            } catch (error: any) {
                setIsProcessing(false);
                setErrorSummary(error);
            }
        }
    }, [
        applicationNumber,
        pageNumber,
        formName,
        applicationForm.configuration?.schema,
        applicationForm.configuration?.uiSchema,
        applicationForm.data,
        abandonmentTracking,
        history,
    ]);

    const onFormLoaded = useCallback(() => {
        setIsFormLoaded(true);
    }, []);

    useEffect(() => {
        let timeleft = 30;

        const redirectTimer = setInterval(function () {
            const element = document?.getElementById("countdowntimer");
            timeleft--;

            if (element?.textContent) {
                element.textContent = String(timeleft);
            }

            if (timeleft <= 0 && !isNil(element)) {
                window.location.reload();
                clearInterval(redirectTimer);
            }
        }, 1000);

        return () => {
            clearInterval(redirectTimer);
        };
    }, []);

    const showRedirectBlock = !isNil(errorSummary) && errorSummary.responseMessage?.includes("Users must wait 30 seconds");
    // Show custom error heading only for error with redirect block.
    const errorHeading = showRedirectBlock ? "One Moment, Please" : undefined;
    const stepperColors = getStepperColors(portalConfig);

    // Show mocked page if opened in portal builder
    if (isInIframe()) {
        return <PortalBuilderPreviewApplicationPage />;
    }

    // Show a message if application is not allowed
    if (!allowPortalApplication) {
        return <ApplicationNotAllowedPage />;
    }

    // Show page not found if application opened with invalid URL.
    if (isNil(pageNumber)) {
        return <PageNotFoundPage />;
    }

    if (redirectToPrescreenPage) {
        const referrer = location.pathname + location.search;
        return <Redirect to={{ pathname: PageLink.Prescreen, search: location.search, state: { referrer } }} />;
    }

    if (isLoading) {
        return (
            <ApplicationPageContainer>
                <div className="m-auto d-flex flex-column justify-content-center align-items-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading application...</span>
                    </Spinner>
                </div>
            </ApplicationPageContainer>
        );
    }

    const activeStep = portalWizardStepList.indexOf(applicationForm.configuration?.uiSchema["af:portalWizardStep"]);

    return (
        <>
            {isProcessing && <ApplicationPageProcessing />}
            <ApplicationPageContainer key={pageNumber} hidden={isProcessing}>
                <ErrorSummary errorHeading={errorHeading} errorSummary={errorSummary} />
                {showRedirectBlock && <RedirectBlock />}
                {!showRedirectBlock && applicationDataLoaded && (
                    <>
                        {!isNaN(activeStep) && activeStep > -1 && (
                            <ApplicationStepper activeStep={activeStep} steps={portalWizardStepList} {...stepperColors} />
                        )}
                        <ApplicationForm
                            formRef={applicationFormRef}
                            contactsWidgetRef={contactsWidgetRef}
                            additionalContactsWidgetRef={additionalContactsWidgetRef}
                            workflowWidgetRef={workflowWidgetRef}
                            encryptState={encryptState}
                            applicationNumber={applicationNumber}
                            configuration={applicationForm.configuration}
                            formData={applicationForm.data}
                            onFormLoaded={onFormLoaded}
                            hidden={!isFormLoaded}
                        />
                        {isFormLoaded && (
                            <ApplicationControls
                                isFirstPage={isFirstPage}
                                isLastPage={isLastPage}
                                onBack={onBack}
                                onContinue={onContinue}
                                onSaveDraft={onSaveDraft}
                                isProcessing={isProcessing}
                                isDisqualificationPage={isDisqualificationPage}
                            />
                        )}
                    </>
                )}
            </ApplicationPageContainer>
        </>
    );
}

const RedirectBlock = () => (
    <p>
        Redirecting to Application Form after <span id="countdowntimer">30 </span> Seconds
    </p>
);

const useAbandonmentTracking = (
    applicationNumber: string | null,
    formName: string | undefined,
    pageNumber: string | null,
    isNewApplication: boolean
) => {
    const abandonmentData = useRef<{
        applicationNumber: string | null;
        formName: string | undefined;
        pageNumber: string | null;
        isNewApplication: boolean;
        pageNavigated: string | null;
    }>({
        applicationNumber,
        formName,
        pageNumber,
        isNewApplication,
        pageNavigated: null,
    });

    abandonmentData.current.applicationNumber = applicationNumber;
    abandonmentData.current.formName = formName;
    abandonmentData.current.pageNumber = pageNumber;

    useEffect(() => {
        const data = abandonmentData.current;

        return () => {
            if (data.pageNavigated !== data.pageNumber && !isNil(data.applicationNumber) && !isNil(data.formName)) {
                sendAnalyticsEvent(AnalyticsEventType.APPLICATION_ABANDONED, {
                    applicationNumber: data.applicationNumber,
                    formName: data.formName,
                    isNewApplication: data.isNewApplication,
                });
            }
        };
    }, []);

    return abandonmentData.current;
};
