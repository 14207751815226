import { Button } from "components/Button";
import { Heading } from "components/Heading";
import { Container } from "components/Layout/Container";
import { AnalyticsEventType, useAnalyticsEvent } from "components/utils/analytics";
import { HeadingLevel } from "types/HeadingLevel";

export function PageNotFoundPage() {
    useAnalyticsEvent(AnalyticsEventType.PAGE_404, { path: document.location.pathname });

    return (
        <Container className="p-0 align-middle text-center" style={{ maxWidth: "389px" }}>
            <Heading level={HeadingLevel.H1} className="fw-bold" style={{ fontSize: "112px" }}>
                404
            </Heading>
            <Heading level={HeadingLevel.H2} className="fs-3 p-2 fw-bold">
                Page not found
            </Heading>
            <p className="fs-6 p-2 text-wrap">The link you clicked may be broken or the page may have been removed or renamed.</p>
            <Button className="p-2 w-100" href="/" target="_self" variant="primary" text="Go to homepage" />
        </Container>
    );
}
